import React, { Fragment } from "react";

const Design = ({}) => {
  return (
  <Fragment>
  <div id="canvas"></div>
  <div id="border"></div>
  <div id="border-top"></div>
  <div id="border-left"></div>
  <div id="border-bottom"></div>
  <div id="border-right"></div>
  <div id="screw-small-right" class="screw small"></div>
  <div id="screw-small-left" class="screw small"></div>
  <div id="screw-large-right" class="screw large"></div>
  <div id="screw-large-left" class="screw large"></div>
  <div id="backboard"></div>
  <div id="motherboard"></div>
  <div id="motherboard-capacitors" class="capacitors"></div>
  <div id="chip-short" class="chip"></div>
  <div id="chip-diagonal" class="chip"></div>
  <div id="chip-tall" class="chip"></div>
  <div id="chip-capacitors" class="capacitors"></div>
  <div id="contrast-knob"></div>
  <div id="link-port"></div>
  <div id="circuit-bottom" class="circuit"></div>
  <div id="circuit-top" class="circuit"></div>
  <div id="transistors"></div>
  <div id="processor"></div>
  <div id="component"></div>
  <div id="controller"></div>
  <div id="speaker"></div>
  <div id="whitescreen"></div>
  <div id="glass"></div>
  <div id="glass-gameboy-text">ETH BOY</div>
  <div id="glass-color-text">F</div>
  </Fragment>
 )
}

export default Design
