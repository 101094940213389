import React, { useEffect, useState, Fragment, useContext } from "react";
import axios from "axios";
import unit from "ethjs-unit";
import _ from "lodash";
import { PSRs } from "utils/psr";
import { CurrentUserContext, Web3ModalContext } from "../../contexts/Store";
import { createWeb3User, signInWithWeb3 } from "../../utils/auth";
import LpBuild from "../../ethereum/artifacts/ERC20Farmed.json"
import FarmBuild from '../../ethereum/artifacts/tokenChef.json'
import tokenBuild from '../../ethereum/artifacts/tokenToken.json'
import token2Build from '../../ethereum/artifacts/Token2.json'
import saleBuild from '../../ethereum/artifacts/sale.json'
import mewImg from '../../style/wildMew.gif'
import { Media, Player, controls } from 'react-media-player'
import About from './about'
import FarmAction from './farmAction'
import StagingForm from './stagingForm'
import PurchaseMenu from './purchaseMenu'
import InputHandler from './inputHandler'
import Design from './design'
import Menu from './menu'
import Navigation from '../Navigation'

// LP
const lpAddressesMap = {
  1: "0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5",
  4: "0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5",
};
//token
const tokenAddressesMap = {
  1: "0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5",
  4: "0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5",
};
//nft
const nftAddressesMap = {
  1: "0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5",
  4: "0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5",
};
// farm
const farmAddressesMap = {
  1: "0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5",
  4: "0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5",
};
// 0xa2DDaEF5D54D2F480d6322da4fCD5b4375298e98
// farm
const saleAddressesMap = {
  1: "0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5",
  4: "0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5",
};

export default () => {
  const [web3Modal, setWeb3Modal] = useContext(Web3ModalContext);
  const [, setCurrentUser] = useContext(CurrentUserContext);
  const [priceLoading, setPriceLoading] = useState(true);
  const [totalTipsLoading, setTotalTipsLoading] = useState(true);
  const [contractLP, setContractLP] = useState(false);
  const [saleContract, setSaleContract] = useState(false);
  const [contractFarm, setContractFarm] = useState(false);
  const [contractToken, setContractToken] = useState(false);
  const [tip, setTip] = useState(false);
  const [accounts, setAccounts] = useState(false);
  const [selectedID, setSelectedID] = useState(undefined);
  const [userLPBalance, setUserLPBalance] = useState(0);
  const [rewards, setRewards] = useState("none");
  const [approved, setApproved] = useState(false);
  const [locked, setLocked] = useState(false);
  const [deposited, setDeposited] = useState(false);
  const [pooledAmt, setAmountStaked] = useState(0);
  const [pending, setPending] = useState("");
  const [burnedAmt, setBurnedAmount] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [multiplierFarm, setMultiplier] = useState(false);
  const [canBonus, setCanBonus] = useState(false);
  const [approveScreenText, setApprovedScreenText] = useState("approve");
  const [withdrawScreenText, setWithdrawScreenText] = useState("withdraw");
  const [claimScreenText, setClaimScreenText] = useState("claim");
  const [depositCheck, setDepositText] = useState("deposit");
  const [ethboyUniPool, setUniPool] = useState("$GET-$ETH");
  const [page, setPage] = useState(0);
  const [customDeposit, setCustomDeposit] = useState('')
  const [staging, setStaging] = useState(false)
  const [stagingActive, setStagingActive] = useState(false)
  const [allowance, setAllowance] = useState(false)
  const [purchaseScreen, setPurchaseScreen] = useState(false)
  const [screenText, setScreenText] = useState("Gamer Ethereum Token")
  const [menu, setMenu] = useState(true);
  const [lge, setLGE] = useState(false);
  const [claimable, setClaimable] = useState(0);



  async function renderInstances() {
    const w3c = await signInWithWeb3();
    let chainId = await w3c.web3.eth.getChainId()
    setWeb3Modal(w3c);

    const accounts = await w3c.web3.eth.getAccounts();
    setAccounts(accounts[0]);
    const user = createWeb3User(accounts[0]);
    setCurrentUser(user);

    const saleInstance = await new w3c.web3.eth.Contract(
      saleBuild.abi,
      saleAddressesMap[await w3c.web3.eth.getChainId()]
    );
    setSaleContract(saleInstance);
    const purchased = await saleInstance.methods.tokensReserved(accounts[0]).call()
    setClaimable(purchased);

    const lpInstance = await new w3c.web3.eth.Contract(
      LpBuild.abi,
      lpAddressesMap[await w3c.web3.eth.getChainId()]
    );
    setContractLP(lpInstance);
    const farmInstance = await new w3c.web3.eth.Contract(
      FarmBuild.abi,
      farmAddressesMap[await w3c.web3.eth.getChainId()]
    );
    setContractFarm(farmInstance);
    const tokenInstance = await new w3c.web3.eth.Contract(
      tokenBuild.abi,
      tokenAddressesMap[await w3c.web3.eth.getChainId()]
    );
    setContractToken(tokenInstance);

    let lpBal = await lpInstance.methods
      .balanceOf(accounts[0])
      .call()
      .then((res) => {
        setUserLPBalance(res)
        if (res > 0) {
          checkApproved(res)
        }
      });

      async function checkApproved(lpBals) {
       await lpInstance.methods
        .allowance(accounts[0],farmAddressesMap[chainId])
        .call()
        .then((res) => {
          console.log(res + " bal " + lpBals)
          setAllowance(res)
          if (res > 0 && res == lpBals)
          setApproved(true)
        });
      }

       let PooledBal = await farmInstance.methods
        .userInfo(0,accounts[0]).call()
        setAmountStaked(PooledBal[0]);

        let pending = await farmInstance.methods
          .pendingtoken(0,accounts[0]).call()
          setPending(pending);

        let burnBal = await tokenInstance.methods
          .balanceOf('0x0000000000000000000000000000000000000000')
          .call()
          .then((res) => {
            setBurnedAmount(unit.fromWei(res, 'ether'))
          });

        let totsupp = await tokenInstance.methods
          .totalSupply()
          .call()
          .then((res) => {
            setTotalSupply(unit.fromWei(res, 'ether').substr(0,6))
          });
      //getinstance
      }

      useEffect(() => {
        renderInstances()

        async function loadData() {
        }

  }, []);
const setClaims = async (e) => {
  const purchased = await saleContract.methods.tokensReserved(accounts).call()
  setClaimable(purchased);
}

  const purchase = async (e) => {
     let value = document.getElementById("inputAmount").value
     let amount = unit.toWei(value,'ether');
      try {
        saleContract.methods
          .buyTokens()
          .send({
            from: accounts,
            to: saleAddressesMap[await web3Modal.web3.eth.getChainId()],
            value:amount
          }, function(error, result) {
              if (error && error.message.includes("User denied transaction signature")) {
              }
          })
          .then((res) => {
          setClaims()
          });
        } catch(err) {
          console.log(err)

        }
  };
  const claimPurchased = async (e) => {
      alert('claiming')
      try {
        saleContract.methods
          .claimTokens()
          .send({
            from: accounts,
            to: saleAddressesMap[await web3Modal.web3.eth.getChainId()]
          }, function(error, result) {
              if (error && error.message.includes("User denied transaction signature")) {
              }
          })
          .then((res) => {
              setClaims()
          });
        } catch(err) {
          console.log(err)

        }
  };


  async function approve  (e) {

    let value = document.getElementById("inputAmount").value
      const FarmContract = farmAddressesMap[await web3Modal.web3.eth.getChainId()]
      let amount = unit.toWei(value,'ether')
      try {
        contractLP.methods
          .approve(FarmContract, amount)
          .send({
            from: accounts,
            to: lpAddressesMap[await web3Modal.web3.eth.getChainId()],
          }, function(error, result) {
              if (error && error.message.includes("User denied transaction signature")) {

              }
            })
          .then((res) => {
            console.log("response: ", res);

            setApproved(true)
            setStagingActive("deposit")
          });
        } catch(err) {
          console.log(err)

        }
  };

    const deposit = async (e) => {
       let value = document.getElementById("inputAmount").value
       let amount = unit.toWei(value,'ether');

        try {
          contractFarm.methods
            .deposit('0', amount,locked)
            .send({
              from: accounts,
              to: farmAddressesMap[await web3Modal.web3.eth.getChainId()],
            }, function(error, result) {
                if (error && error.message.includes("User denied transaction signature")) {

                }
            })
            .then((res) => {
              console.log("response: ", res);
              setDeposited(true)
              setStaging(false)
              setAmountStaked(amount)
              setUserLPBalance(userLPBalance - amount)
            });
          } catch(err) {
            console.log(err)

          }
    };

    const withdraw = async (e) => {
      let amount = document.getElementById("inputAmount").value
      amount = unit.toWei(amount,'ether');
      try {
        contractFarm.methods
          .withdraw('0',amount)
          .send({
            from: accounts,
            to: farmAddressesMap[await web3Modal.web3.eth.getChainId()],
          })
          .then((res) => {
            console.log("response: ", res);
            setDeposited(true)
            setUserLPBalance(amount)
            setAmountStaked(0)
            setPending(0)
          });
        } catch(err) {
          console.log(err)
        }
  };

    const claim = async (e) => {
        try {
          contractFarm.methods
            .claim('0')
            .send({
              from: accounts,
              to: farmAddressesMap[await web3Modal.web3.eth.getChainId()],
            })
            .then((res) => {
              console.log("response: ", res);
              setDeposited(true)
              let pending = contractFarm.methods
                .pendingtoken(0,accounts).call().then((res) => {
                  setPending(res);
                })

            });
          } catch(err) {
            console.log(err)
          }
    };

    const multiplierOn = async (e) => {
        let amount = unit.toWei(userLPBalance,'ether')

        try {
          contractFarm.methods
            .updateBonusBlock()
            .send({
              from: accounts,
              to: farmAddressesMap[await web3Modal.web3.eth.getChainId()],
            }, function(error, result) {
                if (error && error.message.includes("User denied transaction signature")) {

                }
            })
            .then((res) => {
              console.log("response: ", res);
              setMultiplier(true)
            });
          } catch(err) {
            console.log(err)

          }
    };

    const ToEth = (amt) => {
      return unit.fromWei(amt,'ether');
    }

function changeActive(elem) {
  if (page === 0 && !purchaseScreen && !menu) {
    const selection = ["screen-approve-text", "screen-deposit-text", "screen-withdraw-text","screen-claim-text"]
    var current = document.getElementsByClassName("active")[0];
    var remove = document.getElementsByClassName("active")[0].classList.remove("active");
    let index = selection.indexOf(current.id)
    if (elem === "add") {
      index--
      if (index === 4) {
        index = 0
      }
      else if (index === -1) {
        index = 3
      }
    } else {
       index++
      if (index === 4) {
        index = 0
      }
      else if (index === -1) {
        index = 3
      }
    }
    const newActiveId = selection[index]
    const newActive = document.getElementById(newActiveId).classList.add('active')
} else if (purchaseScreen) {
    //purchase screen set active
    const selection = ["screen-purchase-text","screen-uniswap-text","screen-lp-text"]
    var current = document.getElementsByClassName("active")[0];
    var remove = document.getElementsByClassName("active")[0].classList.remove("active");
    let index = selection.indexOf(current.id)
    if (elem === "add") {
      index--
      if (index === 3) {
        index = 0
      }
      else if (index === -1) {
        index = 2
      }
    } else {
       index++
      if (index === 3) {
        index = 0
      }
      else if (index === -1) {
        index = 2
      }
    }
    const newActiveId = selection[index]
    const newActive = document.getElementById(newActiveId).classList.add('active')
   } else if (menu) {
      //purchase screen set active
      const selection = ["screen-menu-lge-text","screen-menu-pool-text","screen-menu-about-text","screen-menu-purchase-text"]
      var current = document.getElementsByClassName("active")[0];
      var remove = document.getElementsByClassName("active")[0].classList.remove("active");
      let index = selection.indexOf(current.id)

      if (elem === "add") {
        index--
        if (index === 4) {
          index = 0
        }
        else if (index === -1) {
          index = 3
        }
      } else {
         index++
        if (index === 4) {
          index = 0
        }
        else if (index === -1) {
          index = 3
        }
      }
      const newActiveId = selection[index]
      const newActive = document.getElementById(newActiveId).classList.add('active')
    } else if (page === 1 && !purchaseScreen) {
        let text = document.getElementById("screen-about-text")
        if (elem === "add") {
          text.scrollTop -= 10;
        } else {
          text.scrollTop += 10;
        }
    }
}

async function getPending() {
let pending1 = await contractFarm.methods
  .pendingtoken(0,accounts).call()
  setPending(pending);
}

function doPurchase() {
  document.getElementsByClassName("active")[0].click()
}

function doMenu() {
  let active = document.getElementsByClassName("active")[0]
  if (active.id === "screen-menu-lge-text") {
    setLGE(true)
  } else if (active.id === "screen-menu-pool-text"){
      setMenu(false);
      setPage(0);
  } else if (active.id === "screen-menu-about-text") {
    setMenu(false);
    setPage(1);
  } else if (active.id === "screen-menu-purchase-text") {
    selectButton()
  }
  if (purchaseScreen) {
   doPurchase();
  }
}

async function doActive() {
  if (page != 0) {
    return false
  } else if (staging) {
    let func = stagingActive === "deposit" ? deposit : stagingActive === "withdraw" ? withdraw : stagingActive === "claim" ? claim : stagingActive === "approve" ? approve : ""
    func()
  } else {
  var current = document.getElementsByClassName("active")[0];
  let action = document.getElementById(current.id).getElementsByTagName('p')[0].innerHTML
  if (action === "claim") {
    await claim()
  } else if (action === "withdraw") {
    setStaging(true)
    setStagingActive(action)
  // await  withdraw()
  } else if (action === "approve") {
    setStaging(true)
    setStagingActive(action)
    // await sendApprove()
  } else if (action === "deposit") {
    setStaging(true)
    setStagingActive(action)
    // await deposit()
  } else if (action === "add to LGE"){
    setStaging(true)
    setStagingActive("deposit")
  }
}
  getPending()
}
// setLGEscreenStaging
async function LGEaction() {
    setLGE(true)
}
// on screen click handler
async function screenAction(e) {
  let action = e.target.innerHTML
  if (action === "claim") {
    await claim()
  } else {
    setStagingActive(action)
    setStaging(true)
  }
  getPending()
}

function startButton() {
    setMenu(!menu);
    setPurchaseScreen(false);
  }

     function selectButton() {
       setPurchaseScreen(!purchaseScreen)
       if (page == 0 && !menu) {
         let p = document.getElementsByClassName('active')[0]
         if (p){
           p.classList.remove("active")
         }
       let bg = document.getElementById('screen').style.background
       if (bg === "black") {
         document.getElementById('screen').style.background = "white"
         document.getElementById("screen-purchase-text").style.display = "none"
         document.getElementById("screen-lp-text").style.display = "none"
         document.getElementById("screen-uniswap-text").style.display = "none"
         document.getElementById("screen-pool-text").style.display = "block"
         document.getElementById('screen-approve-text').classList.add("active")
       } else {
         document.getElementById('screen').style.background = "black"
         document.getElementById("screen-pool-text").style.display = "none"
         document.getElementById("screen-purchase-text").style.display = "block"
         document.getElementById("screen-lp-text").style.display = "block"
         document.getElementById("screen-uniswap-text").style.display = "block"
         let p = document.getElementById('screen-purchase-text').classList.add("active")
       }
     } else if (!menu){
       let p = document.getElementsByClassName('active')[0]
       if (p){
         p.classList.remove("active")
       }
       let bg = document.getElementById('screen').style.background
       if (bg === "black") {
         document.getElementById('screen').style.background = "white"
         document.getElementById("screen-about-text").style.display = "block"
         document.getElementById("screen-gameboy-about").style.display = "none"
         document.getElementById("screen-purchase-text").style.display = "none"
         document.getElementById("screen-purchase-text").style.display = "none"
         document.getElementById("screen-lp-text").style.display = "none"
         document.getElementById("screen-uniswap-text").style.display = "none"
       } else {
         document.getElementById('screen').style.background = "black"
         document.getElementById("screen-about-text").style.display = "none"
         document.getElementById("screen-gameboy-about").style.display = "block"
         document.getElementById("screen-purchase-text").style.display = "block"
         document.getElementById("screen-lp-text").style.display = "block"
         document.getElementById("screen-uniswap-text").style.display = "block"
         let p = document.getElementById('screen-purchase-text').classList.add("active")
       }
     } else if (menu) {
       let p = document.getElementsByClassName('active')[0]
       if (p){
         p.classList.remove("active")
       }
       let bg = document.getElementById("screen-lp-text").style.display
        if (bg === "block") {
        document.getElementById("screen-menu-text").style.display = "block"
        document.getElementById("screen-menu-lge-text").style.display = "block"
        document.getElementById("screen-menu-pool-text").style.display = "block"
        document.getElementById("screen-menu-about-text").style.display = "block"
        document.getElementById("screen-menu-purchase-text").style.display = "block"
        document.getElementById("screen-purchase-text").style.display = "none"
        document.getElementById("screen-lp-text").style.display = "none"
        document.getElementById("screen-uniswap-text").style.display = "none"
        let p = document.getElementById('screen-menu-lge-text').classList.add("active")
        } else {
         document.getElementById("screen-menu-text").style.display = "none"
         document.getElementById("screen-menu-lge-text").style.display = "none"
         document.getElementById("screen-menu-pool-text").style.display = "none"
         document.getElementById("screen-menu-about-text").style.display = "none"
         document.getElementById("screen-menu-purchase-text").style.display = "none"
         document.getElementById("screen-purchase-text").style.display = "block"
         document.getElementById("screen-lp-text").style.display = "block"
         document.getElementById("screen-uniswap-text").style.display = "block"
         let p = document.getElementById('screen-purchase-text').classList.add("active")
       }
     }
    }

     function setMax(max) {

      document.getElementById("inputAmount").value = max
     }
    // joystick click handler
  return (
  <Fragment>
    <Navigation style={{position:'absolute',zIndex:'10000',top:'0px',right:'9px'}} />
    <div className={"background1"}></div>
    <div id="gameboy" class="green">
    <Design/>
     {menu ?
       <Fragment >
       <Menu lge={lge} claimPurchased={claimPurchased} page={page} claimable={claimable} setLGE={setLGE} setPage={setPage} setMenu={setMenu} selectButton={selectButton} purchase={purchase} saleAddressesMap={saleAddressesMap}/>
         <PurchaseMenu screenText={screenText}/>
       </Fragment>
     :

      page == 1 ?
       <Fragment>
         <div id="screen">
          </div>
        <About />
        <PurchaseMenu screenText={screenText}/>
       </Fragment>
     :
      <span>
        <div id="screen">
         </div>
       <div className="screen-user-info">
        <p id="screen-user-LP-balance">
          LP Balance:{unit.fromWei(userLPBalance,'ether').substr(0,6)}
        </p>
        <p id="screen-user-pool-data">
          Pool Balance:{unit.fromWei(pooledAmt,'ether').substr(0,6)}
          <br/>
          Rewards:{unit.fromWei(pending,'ether').substr(0,6)}
        </p>
        </div>

      {staging  ?
        <Fragment>
        <div id="screen">
         </div>
          <StagingForm ethboyUniPool={ethboyUniPool} pooledAmt={pooledAmt} pending={pending} userLPBalance={userLPBalance} unit={unit} InputHandler={InputHandler} allowance={allowance} stagingActive={stagingActive} deposit={deposit} withdraw={withdraw} claim={claim} approve={approve} setMax={setMax} setStaging={setStaging} setCustomDeposit={setCustomDeposit}  />
        </Fragment>
      :
      <div>

          <FarmAction screenAction={screenAction}
            screenText={screenText} ethboyUniPool={ethboyUniPool} approveScreenText={approveScreenText} depositCheck={depositCheck}
             withdrawScreenText={withdrawScreenText}
          claimScreenText={claimScreenText}/>
         //
        <PurchaseMenu />
      </div>
      }
     </span>
     }
      <div id="joystick-pad"></div>
      <div id="joystick">.
        <button disabled={staging} onClick={() => changeActive("add")} id="joystick-top"> </button>
        <button disabled={staging,purchaseScreen} onClick={()=> setPage(page >= 1 ? 0 : 1)} id="joystick-right"> </button>
        <button disabled={staging} onClick={() => changeActive("minus")} id="joystick-bottom"> </button>
        <button disabled={staging,purchaseScreen} onClick={()=> setPage(page == 0 ? 1 : 0)} id="joystick-left"> </button>

      </div>
      <div id="control"></div>

      <button onClick={staging ? () => setStaging(false) : (menu === false || lge )? () => (setMenu(true),setLGE(false),setPage(0)) : () => selectButton()} id="control-b" class="control-button">B</button>

      <button id="control-a" class="control-button" onClick={menu ? doMenu : !purchaseScreen ? doActive : doPurchase}>A</button>
      <div id="start-select-box"></div>
      <button disabled={staging || purchaseScreen} onClick={() => (selectButton())} id="start-select-button"></button>
      <button disabled={staging} onClick={() => startButton()} id="start-start-button"></button>
      <div id="cover-vertical"></div>
      <div id="cover-horizontal"></div>
      <div id="gloss"></div>
      <div id="speaker-holes"></div>
      <div id="power"></div>
    </div>

    <div style={{position:'absolute', display:'block',top:'130px',right:'100px',maxWidth:'220px'}}>

    </div>

    </Fragment>
  );
};
