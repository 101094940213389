import React, { Fragment } from "react";

const About = () => {

  return (
    <Fragment>
    <span className="aboutPage">

      <div id="screen-about-text"><p style={{fontSize:'12px !important'}}>
        Gamer Ethereum Token is a LP farming token with a total supply of 10k.
        Farmers earn $GET while in the pool and will need to time lock their tokens in
        order to pull a NFT. The NFTs will be distributed having chances to pull a rare or non rare.
        Transactions contribute 1% to the farming pool and purchases contribute 1% to the artists treasury.
        90% of the sale will be locked for liquidity and 10% will be reserved to purchase $GET from uniswap
        at the time of NFTs release for distributing to artists. 
      </p>
    </div>
    </span>
  </Fragment>
  );
};


export default About;
