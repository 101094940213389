import React, { Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Layout } from 'antd';
import styled from 'styled-components';

import Routes from './Routes';
import { Footer } from "components/Footer";



const StyledLayout = styled(Layout)`
    flex-direction: column;


`;

const App = () => {
  const { Content } = Layout;

  return (
    <Fragment>
      <Helmet defaultTitle="GET">
        <meta name="description" content="Gamer Ethereum Token" />
      </Helmet>
      <StyledLayout>
        <Router>
            <Content>
              <Routes />
            </Content>
        </Router>
      </StyledLayout>
    </Fragment>
  );
};

export default App;
