import React, {Fragment, useState,useEffect } from 'react'
import InputHandler from './inputHandler'
import {Link} from 'react-router-dom'
import Countdown from 'react-countdown';
import unit from "ethjs-unit";


const menu = ({ setLGE,claimPurchased, saleAddressesMap, purchase, claimable}) => {

  // mock staging form as LGE handler
  // must have lge option add to vault with input

const canClaim = false;

  // Random component
  const Completionist = () => <span>You are good to go!</span>;

  // Renderer callback with condition
  const renderer = ({days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return <span>{days} days {hours} Hours <br/> </span>;
    }
  };

  function setMax() {
   document.getElementById("inputAmount").value = 5
  }

return (
  <Fragment>
    <div id="screen-menu"></div>
      <p  className="actionHeader" style={{top:'75px',fontSize:'13px !important',color:'#d5aaff'}}>
        <br/>soon
      </p>
      <div className="screen-user-info">
       <p style={{color:'white'}} id="screen-user-LP-balance">
         Reserved:{unit.fromWei(claimable,'ether').substr(0,6)}
       </p>
       <p style={{color:'white'}} id="screen-user-pool-data">
         Max available:120
         <br/>
         Max Eth:5
       </p>
       </div>
      <div onClick={() => setMax()} style={{color:'yellow'}} id="screen-deposit-sale"><p>Deposit ETH to play</p></div>
      <span style={{color:'black',position:'absolute',zIndex:'100',top:'160px',left:'33px'}}>
      <InputHandler />
        <br/><br/>
        <div className="cards">
          <button className="card" onClick={canClaim ? (() => claimPurchased()) : () => setLGE(false)}> back </button>
          <button disabled={true} onClick={purchase} className="card"> submit </button>
        </div>
      </span>
</Fragment>

)

}



export default menu
