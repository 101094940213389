import React, { useEffect, useState, Fragment, useContext } from "react";
import { Table, Button, Modal, Input, Alert } from "antd";
import axios from "axios";
import unit from "ethjs-unit";
import _ from "lodash";
import { PSRs } from "utils/psr";
import { CurrentUserContext, Web3ModalContext } from "../../contexts/Store";
import { createWeb3User, signInWithWeb3 } from "../../utils/auth";
import LpBuild from "../../ethereum/build/ERC20Farmed.json"
import FarmBuild from '../../ethereum/build/PadThaiChef.json'
import tokenBuild from '../../ethereum/build/PadThaiToken.json'
import token2Build from '../../ethereum/build/Token2.json'
import {Link} from 'react-router-dom'

const { Column } = Table;
// LP
const lpAddressesMap = {
  1: "0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5",
  4: "0xe960DcA3a952d25F64aCe16d7638F345920580fC",
};
//token
const tokenAddressesMap = {
  1: "0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5",
  4: "0xf315eF3918DCdFeDb51c1A12d7b2A8Ac1FacefB3",
};
// farm
const farmAddressesMap = {
  1: "0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5",
  4: "0x34DE7C6b4713afed6eCd7e9a9C04cf75e23653A8",
};

export default () => {
  const [web3Modal, setWeb3Modal] = useContext(Web3ModalContext);
  const [, setCurrentUser] = useContext(CurrentUserContext);
  const [priceLoading, setPriceLoading] = useState(true);
  const [totalTipsLoading, setTotalTipsLoading] = useState(true);
  const [contractLP, setContractLP] = useState(false);
  const [contractFarm, setContractFarm] = useState(false);
  const [contractToken, setContractToken] = useState(false);
  const [tip, setTip] = useState(false);
  const [visible, setVisible] = useState(false);
  const [accounts, setAccounts] = useState(false);


  async function renderInstances() {
    const w3c = await signInWithWeb3();
    setWeb3Modal(w3c);

    const accounts = await w3c.web3.eth.getAccounts();
    setAccounts(accounts[0]);
    const user = createWeb3User(accounts[0]);
    setCurrentUser(user);

    }

      useEffect(() => {
        const apiPromises = [];
        const priceAPIPromises = [];
        renderInstances()

  }, []);

  const handleCancel = (e) => {
    setVisible(false);
  };

  const isTipValid = () => {
    const numericTip = parseFloat(tip.replaceAll(',', '.'));

    return !isNaN(numericTip) && numericTip > 0;

    }

    const tipToWei = () => {
        return web3Modal.web3.utils.toWei(tip.replaceAll(',', '.'), 'ether');
    }

    const ToEth = (amt) => {
      return unit.fromWei(amt,'ether');
    }

  return (
    <Fragment>
      <div style={{marginBottom:'40px',display:'relative',paddingBottom:'40px'}}>
        <div className="background1">
        </div>
            <div style={{position:"absolute",left:'0px', width:'100%'}}>
              <div className="container1">
            <header>
                <div style={{color:'white',width:'100%'}}>
                    <div className="container1" style={{fontSize:'20px',width:'90%',paddingTop:'20px',float:'right',color:'white',position:'relative', width:'100%'}}>
                      <h4 style={{textAlign:'center',color:'white'}}>SALE</h4>
                      <ul style={{listStyle:'cjk-earthly-branch'}}>
                      <li className="litem">45000 Max Supply</li>
                      <li className="litem">18 TYDE to 1 eth</li>
                      <li className="litem">15000 TYDE in presale</li>
                      <li className="litem">2000 tokens for liquidity</li>
                      <li className="litem">Presale liquidity will be provided for TYDE and CVRRENT</li>
                      <li>CONTRACT: <a target="_blank" href="https://etherscan.io">0x03024342443324</a></li>
                    </ul>
                    <h4 style={{textAlign:'center',color:'white'}}>vYield</h4>
                    <ul style={{listStyle:'cjk-earthly-branch'}}>
                      <li className="litem">CVRRENT is TYDE's volume yielding token</li>
                      <li className="litem">All CVRRENT is produced by TYDE ETH swaps from dexs'(uni)</li>
                      <li className="litem">Buys and sells from uniswap yields CVRRENT at 2% of the TYDE in tx.</li>
                      <li className="litem">Max Supply of CVRRENT is 9000</li>
                      <li className="litem">each tx burns 2% of total supply | yielding from TYDE doesn't burn CVRRENT</li>
                      <li className="litem">CvRRENT farms must lock TyDE equal TyDE lp for 2 weeks to join</li>
                      <li> CONTRACT: <a target="_blank" href="https://etherscan.io">0x03024342443324</a></li>
                  </ul>
                  <h4 style={{textAlign:'center',color:'white'}}>Burn and Farm</h4>
                    <ul style={{listStyle:'cjk-earthly-branch'}}>
                      <li className="litem">TYDE farming 2x multiplier runs for 4000 blocks after every 2000 TYDE tokens burned milestone.</li>
                      <li className="litem">ATTN: Farming will be set up after the vYield for buys and sells on uniswap is up.</li>
                   </ul>
                  </div>
                </div>
            </header>
          </div>
            <main>
              <div style={{left:'0px',width:'100%'}} class="container">
                  <div className="container1" style={{height:'270px',width:'90%',paddingTop:'30px',float:'right',color:'white',position:'relative', width:'100%'}}>
                  <div className="grid2 mobileHide">
                    <a className="buttonLink mobileBTN1" target="blank" href="https://app.uniswap.org/#/swap">Get $tyde</a>
                    <a className="buttonLink mobileBTN1" href="/staking">Staking Soon</a>
                    </div>
                    <div className="mobileView">
                      <a className="buttonLink mobileBTN1" target="blank" href="https://app.uniswap.org/#/swap">Get $tyde</a>
                      <a className="buttonLink mobileBTN1" href="/staking">Staking Soon</a>
                  </div>
                </div>
                <section className="disclaimer">
                    <div className="text-section" style={{textAlign:'center',color:"white"}}>
                      <p style={{textAlign:'center'}}>PLAY is an unaudited smart contract experiment on the ethereum network. Users, take the time to observe your own regulations and risks involved.</p>
                    </div>
                </section>
              </div>


            </main>

          </div>

      </div>
    </Fragment>
  );
};
