import React, {Fragment, useEffect } from 'react'
import InputHandler from './inputHandler'
import LGEform from './lgeForm'
import {Link} from 'react-router-dom'

const menu = ({lge, setLGE, setPage, setMenu, selectButton,purchase, saleAddressesMap,claimable,page,claimPurchased}) => {

  // mock staging form as LGE handler
  // must have lge option add to vault with input
function goToAbout() {

}

return (
  <Fragment>
  { lge ?
     <LGEform claimPurchased={claimPurchased} claimable={claimable} purchase={purchase} saleAddressesMap={saleAddressesMap} setLGE={setLGE} />
    :
    <Fragment>
    <div id="screen-menu"></div>
    <div id="screen-menu-gameboy-text"><p>Gamer Ethereum Token</p></div>
    <div id="screen-menu-text"><p>Select</p></div>
    <div className={"active"} id="screen-menu-lge-text"><p onClick={(e) => setLGE(true)}>Presale Soon</p></div>
    <div id="screen-menu-pool-text"><p onClick={() => (() => setPage(0),setMenu(false))}>Pool A</p></div>
    <div id="screen-menu-about-text"><p onClick={() => (setMenu(false),setPage(1))}>Readme</p></div>
    <div id="screen-menu-purchase-text"><p onClick={() => selectButton()}>UNISWAP</p></div>
    </Fragment>
  }
  </Fragment>

)

}



export default menu
