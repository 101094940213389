import React, { Fragment } from "react";

const PurchaseMenu = ({screenText,}) => {
    return (
      <span>
        <div id="screen-gameboy-about"><p>{screenText}</p></div>
        <div><a id="screen-purchase-text" target="_blank" href="https://uniswap.org"><p>Uniswap $GET</p></a></div>
        <div><a id="screen-uniswap-text" target="_blank" href="https://uniswap.org"><p>Uniswap Info</p></a></div>
        <div><a id="screen-lp-text" target="_blank" href="https://uniswap.org"><p>Get LP Tokens</p></a></div>
      </span>
    )
  }
export default PurchaseMenu;
