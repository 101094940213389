import React, {useContext, useState} from "react";
import { RightCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import {Web3SignIn} from "../shared/Web3SignIn";
import {CurrentUserContext} from "../../contexts/Store";
import {Link} from 'react-router-dom'

const StyledHeader = styled.div`
    z-index:1;
`;

const truncateAddr = (addr) => {
  return addr.slice(0, 7) + '...';
};

const Navigation = () => {
  const [currentUser, setCurrentUser] = useContext(CurrentUserContext);

  return (
    <StyledHeader>
      <span style={{position:'absolute',top:'71px',zIndex:'10',left:'calc(50% - 130px)'}}>
        {!currentUser ? (
            <Web3SignIn setCurrentUser={setCurrentUser} />
        ) : (
            <span>connected: {truncateAddr(currentUser.username)}</span>
        )}
      </span>
    </StyledHeader>
  );
};

export default Navigation;
