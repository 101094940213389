import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './components/Home';
import Staking from './components/Staking';
import Gameboy from './components/Gameboy';


const Routes = () => (
  <Switch>
    <Route path="/" exact component={Gameboy} />
    <Route path="/staking" exact component={Staking} />
      <Route path="/gameboy" exact component={Gameboy} />
    <Route path="*" component={Home} />
  </Switch>
);

export default Routes;
