import React from 'react'

 const InputHandler = () =>  {
return(
   <input
         style={{left:'60px',position:'relative'}}
         name="item"
         id="inputAmount"
         type="text"
         key="fatbottom"
          style={{left:'60px',position:'relative'}}
         />
    )
};

export default InputHandler
