import React, { useEffect, useState, Fragment, useContext } from "react";
import axios from "axios";
import unit from "ethjs-unit";
import _ from "lodash";
import { PSRs } from "utils/psr";
import { CurrentUserContext, Web3ModalContext } from "../../contexts/Store";
import { createWeb3User, signInWithWeb3 } from "../../utils/auth";
import LpBuild from "../../ethereum/build/ERC20Farmed.json"
import FarmBuild from '../../ethereum/build/PadThaiChef.json'
import tokenBuild from '../../ethereum/build/PadThaiToken.json'
import token2Build from '../../ethereum/build/Token2.json'


// LP
const lpAddressesMap = {
  1: "0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5",
  4: "0xe960DcA3a952d25F64aCe16d7638F345920580fC",
};
//token
const tokenAddressesMap = {
  1: "0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5",
  4: "0xf315eF3918DCdFeDb51c1A12d7b2A8Ac1FacefB3",
};
// farm
const farmAddressesMap = {
  1: "0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5",
  4: "0x34DE7C6b4713afed6eCd7e9a9C04cf75e23653A8",
};

export default () => {
  const [web3Modal, setWeb3Modal] = useContext(Web3ModalContext);
  const [, setCurrentUser] = useContext(CurrentUserContext);
  const [priceLoading, setPriceLoading] = useState(true);
  const [totalTipsLoading, setTotalTipsLoading] = useState(true);
  const [contractLP, setContractLP] = useState(false);
  const [contractFarm, setContractFarm] = useState(false);
  const [contractToken, setContractToken] = useState(false);
  const [tip, setTip] = useState(false);
  const [visible, setVisible] = useState(false);
  const [accounts, setAccounts] = useState(false);
  const [selectedID, setSelectedID] = useState(undefined);
  const [userLPBalance, setUserLPBalance] = useState();
  const [rewards, setRewards] = useState("none");
  const [approved, setApproved] = useState(false);
  const [deposited, setDeposited] = useState(false);
  const [pooledAmt, setAmountStaked] = useState(0);
  const [pending, setPending] = useState("");
  const [burnedAmt, setBurnedAmount] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [multiplierFarm, setMultiplier] = useState(false);
  const [canBonus, setCanBonus] = useState(false);





  async function renderInstances() {
    const w3c = await signInWithWeb3();
    setWeb3Modal(w3c);

    const accounts = await w3c.web3.eth.getAccounts();
    setAccounts(accounts[0]);
    const user = createWeb3User(accounts[0]);
    setCurrentUser(user);

    const lpInstance = await new w3c.web3.eth.Contract(
      LpBuild.abi,
      lpAddressesMap[await w3c.web3.eth.getChainId()]
    );
    setContractLP(lpInstance);
    const farmInstance = await new w3c.web3.eth.Contract(
      FarmBuild.abi,
      farmAddressesMap[await w3c.web3.eth.getChainId()]
    );
    setContractFarm(farmInstance);
    const tokenInstance = await new w3c.web3.eth.Contract(
      tokenBuild.abi,
      tokenAddressesMap[await w3c.web3.eth.getChainId()]
    );
    setContractToken(tokenInstance);

    let lpBal = await lpInstance.methods
      .balanceOf(accounts[0])
      .call()
      .then((res) => {
        setUserLPBalance(unit.fromWei(res, 'ether'))
        if (res > 0) {
          checkApproved(res)
        }
      });

      async function checkApproved(lpBals) {
       await lpInstance.methods
        .allowance(accounts[0],farmAddressesMap[await w3c.web3.eth.getChainId()])
        .call()
        .then((res) => {
          console.log(res + " bal " + lpBals)
          if (res > 0 && res == lpBals)
          setApproved(true)
        });
      }

       let PooledBal = await farmInstance.methods
        .userInfo(0,accounts[0]).call()
        setAmountStaked(PooledBal[0]);

        // let canMultiply = await farmInstance.methods
        //  .canBonus().call()
        //  setCanBonus(canMultiply);

        let pending = await farmInstance.methods
          .pendingPadthai(0,accounts[0]).call()
          setPending(pending);

        let burnBal = await tokenInstance.methods
          .balanceOf('0x0000000000000000000000000000000000000000')
          .call()
          .then((res) => {
            setBurnedAmount(unit.fromWei(res, 'ether'))
          });

        let totsupp = await tokenInstance.methods
          ._totalSupply()
          .call()
          .then((res) => {
            setTotalSupply(unit.fromWei(res, 'ether').substr(0,6))
          });


      //getinstance
      }



      useEffect(() => {
        const apiPromises = [];
        const priceAPIPromises = [];
        renderInstances()

        async function loadData() {
        }

  }, []);


  const sendApprove = async (e) => {
      setVisible(true)
      const FarmContract = '0x34DE7C6b4713afed6eCd7e9a9C04cf75e23653A8'
      let amount = unit.toWei(userLPBalance,'ether')
      try {
        contractLP.methods
          .approve(FarmContract, amount)
          .send({
            from: accounts,
            to: lpAddressesMap[await web3Modal.web3.eth.getChainId()],
          }, function(error, result) {
              if (error && error.message.includes("User denied transaction signature")) {
                setVisible(false)
              }
            })
          .then((res) => {
            console.log("response: ", res);
            setVisible(false)
            setApproved(true)
          });
        } catch(err) {
          console.log(err)
          setVisible(false)
        }
  };


    const deposit = async (e) => {
        let amount = unit.toWei(userLPBalance,'ether')
        setVisible(true)
        try {
          contractFarm.methods
            .deposit('0', amount)
            .send({
              from: accounts,
              to: farmAddressesMap[await web3Modal.web3.eth.getChainId()],
            }, function(error, result) {
                if (error && error.message.includes("User denied transaction signature")) {
                  setVisible(false)
                }
            })
            .then((res) => {
              console.log("response: ", res);
              setDeposited(true)
              setVisible(false)
              setAmountStaked(amount)
            });
          } catch(err) {
            console.log(err)
            setVisible(false)
          }
    };

    const withdraw = async (e) => {
      try {
        contractFarm.methods
          .withdraw('0',pooledAmt)
          .send({
            from: accounts,
            to: farmAddressesMap[await web3Modal.web3.eth.getChainId()],
          })
          .then((res) => {
            console.log("response: ", res);
            setDeposited(true)
            setUserLPBalance(pooledAmt)
            setAmountStaked(0)

          });
        } catch(err) {
          console.log(err)
        }
  };


    const claimRewards = async (e) => {

        let amount = unit.toWei(userLPBalance,'ether')
        try {
          contractFarm.methods
            .withdrawReward('0')
            .send({
              from: accounts,
              to: farmAddressesMap[await web3Modal.web3.eth.getChainId()],
            })
            .then((res) => {
              console.log("response: ", res);
              setDeposited(true)
              let pending = contractFarm.methods
                .pendingPadthai(0,accounts).call().then((res) => {
                  setPending(res);
                })

            });
          } catch(err) {
            console.log(err)
          }
    };
    const multiplierOn = async (e) => {
        let amount = unit.toWei(userLPBalance,'ether')

        try {
          contractFarm.methods
            .updateBonusBlock()
            .send({
              from: accounts,
              to: farmAddressesMap[await web3Modal.web3.eth.getChainId()],
            }, function(error, result) {
                if (error && error.message.includes("User denied transaction signature")) {
                  setVisible(false)
                }
            })
            .then((res) => {
              console.log("response: ", res);
              setMultiplier(true)
            });
          } catch(err) {
            console.log(err)
            setVisible(false)
          }
    };


  const handleCancel = (e) => {
    setVisible(false);
  };

  const isTipValid = () => {
    const numericTip = parseFloat(tip.replaceAll(',', '.'));

    return !isNaN(numericTip) && numericTip > 0;

    }

    const tipToWei = () => {
        return web3Modal.web3.utils.toWei(tip.replaceAll(',', '.'), 'ether');
    }

    const ToEth = (amt) => {
      return unit.fromWei(amt,'ether');
    }


  return (
    <Fragment>
      <div style={{marginBottom:'40px',display:'relative',paddingBottom:'40px'}}>
      <div className="background1">
      </div>
      <div  style={{background:'rgba(0,0,0,.61)',position:'fixed',minHeight:"1400px",width:'100%'}}>
        <span style={{textAlign:'center'}}><h3 style={{color:'white'}}>BURNED AMOUNT: {burnedAmt} </h3></span>
        { canBonus ?
          <button style={{background:'white',color:'black'}} onClick={multiplierOn}> turn on multiplier </button>
        : null }
        <div className="cards" >
            <span style={{border:"none"}}className="card"></span>
            <span className="card cardShadow" style={{fontSize:'15px',minWidth:'340px'}}>
            <span style={{minHeight:'150px',position:'relative'}}>
              <p style={{margin:'0'}}>
                This is where you compete for farming
              <br/>
                {userLPBalance > 0 ?
                 <bold>Uni Balance: {userLPBalance}</bold>
                :
                 <bold>Uni Balance: Get Farm Uni LP </bold>
                }
              <br/>
              {
              pooledAmt <= 0 ?
              <span style={{minHeight:'30px',width:'100%',position:'relative'}}>
                Pool Balance:{unit.fromWei(pooledAmt,'ether').substr(0,6)}
                <button className={"withdrawBTN"}> Withdraw </button>
                </span>              :
              <span style={{minHeight:'30px',width:'100%',position:'relative'}}>
                Pool Balance:{unit.fromWei(pooledAmt,'ether').substr(0,6)}
                <button onClick={withdraw} className={"withdrawBTN"}> Withdraw </button>
                </span>
              }
              <br/>
              {pending > 0 ?
                <span style={{minHeight:'30px',width:'100%',position:'relative'}}>
                  <bold>Rewards:{unit.fromWei(pending,'ether').substr(0,6)}</bold>
                  <button onClick={claimRewards} className={"withdrawBTN"}> Claim </button>
                </span>
              :
              <span style={{minHeight:'30px',width:'100%',position:'relative'}}>
                <bold style={{minHeight:'30px',width:'100%',position:'relative'}}>Rewards:{pending == 0 ? "0" : pending}</bold>
                <button className={"withdrawBTN"}> Claim </button>
                </span>

              }
              </p>
              </span>
              { !approved ?
                userLPBalance != 0 ?
                <button onClick={sendApprove} disabled={!accounts} className="stakeAction" >
                  Approve (1/2)
                </button>
                :
                <a href="https://uniswap.org" target="_blank"><button className="stakeAction" >
                  Get LP tokens
                </button></a>
              : !deposited ?
                <button onClick={deposit} className="stakeAction">
                  Add stake (2/2)
                </button>
                :
                <span style={{color:'black',background:'lightblue',width:'100%'}}>
                  Success
                </span>
              }

            </span>
            <span style={{border:"none"}} className="card"></span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
