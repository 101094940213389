import React, { Fragment } from "react";

const FarmAction = ({
  screenText,ethboyUniPool,approveScreenText,depositCheck,
    withdrawScreenText,claimScreenText,screenAction}) => {



  return (
    <Fragment>
      <div id="screen-gameboy-text"><p>{screenText}</p></div>
      <div id="screen-pool-text"><p>{ethboyUniPool}</p></div>
      <div className={"active"} id="screen-approve-text"><p onClick={(e) => screenAction(e)}>{approveScreenText}</p></div>
      <div id="screen-deposit-text"><p onClick={(e) => screenAction(e)}>{depositCheck}</p></div>
      <div id="screen-withdraw-text"><p onClick={(e) => screenAction(e)}>{withdrawScreenText}</p></div>
      <div id="screen-claim-text"><p onClick={(e) => screenAction(e)}>{claimScreenText}</p></div>
      <div id="screen-bottom-text"></div>
  </Fragment>
)
}


export default FarmAction;
