import React, { Fragment } from "react";

const StagingForm = ({ethboyUniPool,pooledAmt,pending,userLPBalance,unit,InputHandler,allowance,stagingActive,deposit,withdraw,claim,approve,setMax,setStaging,setCustomDeposit}) => {

   let allowed = allowance > 0 ? allowance : 0

   let func = stagingActive === "deposit" ? deposit : stagingActive === "withdraw" ? withdraw : stagingActive === "claim" ? claim : stagingActive === "approve" ? approve : ""
   let max = stagingActive === "deposit" ? allowed : stagingActive === "withdraw" ? pooledAmt : stagingActive === "claim" ? pending : stagingActive === "approve" ? userLPBalance : 0
   max = unit.fromWei(max,'ether').substr(0,6)
    return (
      <span>
          <h1 className="actionHeader">{stagingActive.toUpperCase()}</h1>
          <div id="screen-pool-text"><p>{ethboyUniPool}</p></div>
        <div id="screen-max-deposit"><p onClick={(e) => setMax(max)}>u s e  M a x</p></div>
          <span style={{color:'black',position:'absolute',zIndex:'100',top:'160px',left:'33px'}}>
          <InputHandler />
            <br/><br/>
            <div className="cards">
              <button style={{background:'black',color:'white'}} className="card" onClick={() => (setStaging(false),setCustomDeposit(0))}> back </button>
              <button disabled={true} style={{background:'black',color:'white'}} className="card" onClick={() => func()}> continue </button>
            </div>
          </span>
      </span>
    )
  }
export default StagingForm;
